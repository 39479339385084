import location from 'assets/icons/location.svg'
import email from 'assets/icons/email.svg'
import telephone from 'assets/icons/telephone.svg'
import whatsapp from 'assets/icons/whatsapp.svg'

export default function Contact() {
  return (
    <section className='flex w-full items-center justify-center'>
      <main className='my-10 grid w-11/12 grid-cols-1 items-center gap-8 md:my-20 md:w-10/12 md:grid-cols-2'>
        <section className='flex flex-col gap-4 text-lg'>
          <ul className='flex flex-col gap-8'>
            <li>
              <a className='flex flex-col gap-2' target='_blank' href='https://maps.app.goo.gl/9dDh6LFrCR1tPYfEA'>
                <b>Endereço:</b>
                <span>RUA PRIMEIRO DE JANEIRO, 2668, IMIGRANTES - PALOTINA, PR, 85950-000</span>
              </a>
            </li>
            <li>
              <a className='flex flex-col gap-2' target='_blank' href='mailto:orcamento@ictus.sola'>
                <b>E-mail:</b>
                <span>orcamento@ictus.solar</span>
              </a>
            </li>
            <li>
              <a className='flex flex-col gap-2' target='_blank' href='https://wa.me/554436498400'>
                <b>Telefone:</b>
                <span>(44) 3649-8400</span>
              </a>
            </li>
            <li>
              <a className='flex flex-col gap-2' target='_blank' href='https://wa.me/554436498400'>
                <b>Whatsapp:</b>
                <span>Clique aqui para conversar</span>
              </a>
            </li>
          </ul>
        </section>
        <section className='flex flex-col gap-4'>
          <header>
            <h3 className='text-3xl font-medium uppercase'>Simule aqui!</h3>
          </header>
          <iframe
            src='https://forms.ploomes.com/form/26ac93cbd7ba44698791efd6fdfb0986?iframe=true'
            className='border-gray h-[500px] rounded-xl border-2 bg-slate-100 md:h-[500px] md:w-[500px]'
          ></iframe>
        </section>
      </main>
    </section>
  )
}
