import about from '../../assets/images/about.png'

export default function Details() {
  return (
    <article className='flex w-full items-center justify-center'>
      <div className='mb-10 mt-20 grid w-11/12 grid-cols-1 md:grid-cols-2 gap-8 md:mb-20 md:mt-40 md:w-9/12'>
        <picture className='relative w-full'>
          <img src={about} className='rounded-lg' alt="" />
        </picture>

        <div className='flex flex-col gap-4 md:text-xl'>
          <header>
            <h3 className='text-3xl font-semibold uppercase md:text-5xl'>Quem Somos</h3>
          </header>

          <p className='text-justify'>
            Somos uma empresa especializada, que com dedicação e transparência, estamos
            instalados em diferentes regiões do Brasil, como Paraná, Rio Grande do Sul, Mato Grosso, Mato Grosso do Sul, Santa
            Catarina e Distrito Federal, em uma variedade de projetos que incluem residências, estabelecimentos
            comerciais, propriedades rurais, industrias e órgãos públicos. A confiança dos nossos clientes em nosso
            trabalho é o que garante a geração média de 445.000 kWh por mês, distribuídos em mais de 50 cidades do nosso
            país.
          </p>

          <p className='text-justify'>
            Na ICTUS SOLUÇÕES EM ENERGIA, damos importância ao valor único de cada indivíduo e suas necessidades. Nosso
            compromisso vai além de simples propostas comerciais, o nosso compromisso está em gerar conexões
            significativas com nossos clientes com transparência e honestidade.
          </p>

          <p className='text-justify'>
            <b className='uppercase'>Missão:</b> Oferecer soluções em energia renovável e tecnologia, com sustentabilidade, em um padrão de excelência que faz com que todas as relações, seja com os colaboradores ou sociedade, deixe um legado com orgulho de ser contado às próximas gerações.
          </p>

          <p className='text-justify'>
            <b className='uppercase'>Visão:</b> Ser reconhecida como empresa referência em integridade e
            profissionalismo, que entrega mais do que promete, tanto por colaboradores e toda sociedade.
          </p>

          <p className='text-justify'>
            <b className='uppercase'>Valores:</b> “Assim na terra como no céu”; Excelência; Integridade e Inovação.
          </p>
        </div>
      </div>
    </article>
  )
}
