import React from 'react';
import background from 'assets/images/projetandoSol.png';
import title from 'assets/images/title.svg';
import alysson from 'assets/images/alysson.png';
import fernando from 'assets/images/fernando.png';
import clarissa from 'assets/images/clarissa.png';

function ProjetandoSol() {
    return (
        <section className='flex justify-center items-center w-full bg-cover bg-center bg-no-repeat bg-green bg-opacity-90'>
            <div className='flex flex-col justify-center items-center gap-8 w-11/12 md:w-9/12 my-8 md:my-20 text-white'>
                {/* Header */}
                <header className='flex flex-col gap-4 justify-center items-center mb-8 text-center'>
                    <picture className='w-full md:w-10/12'>
                        <img src={title} alt="Seminário Projetando com o Sol" />
                    </picture>
                    <p className='text-md md:text-xl font-semibold'>
                        Faça sua inscrição agora mesmo e explore o futuro da tecnologia de energia solar na arquitetura com a ICTUS e suas inovações.
                    </p>
                    <p className='text-lg md:text-xl text-yellow font-semibold'>
                        28 de Setembro, Sábado às 14:30, Associação Comercial de Palotina.
                    </p>

                    <a
                        href='https://forms.ploomes.com/form/bf938f7a2c36429c97a525379688d6f4'
                        target='_blank'
                        rel='noopener noreferrer'
                        className="bg-[#FFE500] text-lg md:text-xl text-black p-4 rounded-xl text-center font-bold uppercase hover:opacity-75 animate-bounce mt-8"
                    >
                        Garanta sua vaga
                    </a>
                </header>

                <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
                    {/* Alysson Guayume Section */}
                    <div className='flex flex-col gap-8 w-full'>
                        <img src={alysson} alt="Alysson Guayume" className='rounded-xl' />
                        <aside className='flex flex-col gap-4'>
                            <header>
                                <h3 className='text-5xl font-medium font-gistesy text-yellow mb-4'>Alysson Guayume</h3>
                                <span className='text-xl uppercase'>Diretor | ICTUS Soluções em Energia</span>
                            </header>
                            <p className='text-lg'>
                                Alysson Guayume possui experiência com consultoria e comercialização de sistemas fotovoltaicos desde 2017. Ele atende integradores e consumidores em todo o país, trazendo um profundo conhecimento e expertise para o setor.
                            </p>
                        </aside>
                    </div>

                    {/* Clarissa Zomer Section */}
                    <div className='flex flex-col gap-8 w-full'>
                        <img src={clarissa} alt="Clarissa Zomer" className='rounded-xl' />
                        <aside className='flex flex-col gap-4'>
                            <header>
                                <h3 className='text-5xl font-medium font-gistesy text-yellow mb-4'>Clarissa Zomer</h3>
                                <span className='text-xl uppercase'>Arquiteta, Dra. Eng. Civil</span>
                            </header>
                            <p className='text-lg text-justify'>
                                Clarissa Zomer é especialista em Arquitetura Solar e atua como CEO da Arquitetando Energia Solar. Ela também ocupa cargos importantes como Diretora de BIPV da ABGD e Diretora de Projetos Arquitetônicos da Garantia Solar BIPV. Além disso, é colunista da Revista PV Magazine Brasil.
                            </p>
                        </aside>
                    </div>

                    {/* Fernando Honorio Silva Section */}
                    <div className='flex flex-col gap-8 w-full'>
                        <img src={fernando} alt="Fernando Honorio Silva" className='rounded-xl' />
                        <aside className='flex flex-col gap-4'>
                            <header>
                                <h3 className='text-5xl font-medium font-gistesy text-yellow mb-4'>Fernando Honorio Silva</h3>
                                <span className='text-xl uppercase'>Coordenador Nacional de Vendas Técnicas | Eternit S/A</span>
                            </header>
                            <p className='text-lg text-justify'>
                                Fernando Honorio Silva é um especialista em Energia Renovável e Gestão de Negócios. Atualmente, é Coordenador Nacional de Vendas Técnicas e Supervisor Comercial Fotovoltaico na Eternit S/A. Ele se destaca pela sua expertise em Estratégias Comerciais e Desenvolvimento de Projetos.
                            </p>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProjetandoSol;
