import React, { useEffect } from 'react'
import Header from 'components/Header'
import Contact from 'components/Contact/Contact'
import Service from 'components/Service/Service'
import Footer from 'components/Footer/Footer'
import Bottom from 'components/Bottom/Bottom'

export default function Contato() {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'Ictus - Contatos'
  }, [])

  return (
    <main className='bg-gray-300'>
      <Header />
      <div className='py-20'>
        <Contact />
      </div>
      <Footer />
      <Bottom />
    </main>
  )
}
