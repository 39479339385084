import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from 'assets/icons/logo.png'
import menu from 'assets/icons/menu.svg'
import Navigation from 'components/Navigation'

function Header() {
  const [showNavigation, setShowNavigation] = useState(false)
  const handleClick = () => setShowNavigation(!showNavigation)

  return (
    <>
      <header className='absolute top-0 z-30 flex w-full items-center justify-center'>
        <nav className='mx-4 my-8 flex w-full justify-between md:mx-36 md:my-10'>
          <Link to='https://ictus.solar/'>
            <picture>
              <img src={logo} alt='Logo da Ictus Soluções em Energia' loading='lazy' className='w-52 md:w-72' />
            </picture>
          </Link>

          <picture onClick={handleClick}>
            <img src={menu} alt='Botão de Menu' loading='lazy' className='h-10 w-10 md:h-12 md:w-12' />
          </picture>
        </nav>
      </header>

      <Navigation isOpen={showNavigation} onClose={() => setShowNavigation(false)} />
    </>
  )
}

export default Header
