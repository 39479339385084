import React from 'react'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade, Pagination, Autoplay } from 'swiper/modules'

import star from 'assets/icons/stars.svg'
import sandrinho from 'assets/images/sandrinho.svg'
import joao from 'assets/images/joao.svg'
import profile from 'assets/images/profile.svg'

export default function Depoimentos() {
  return (
    <section id='home' className='flex w-full flex-col items-center gap-4'>
      <div className='my-10 flex w-11/12 flex-col gap-4 md:my-20 md:w-10/12'>
        <header className='flex flex-col gap-2'>
          <h2 className='text-2xl font-semibold md:text-4xl'>QUEREMOS QUE FAÇA PARTE DE NOSSA HISTÓRIA</h2>

          <p className='text-justify uppercase md:text-xl'>Faça Como nossos clientes: Seja Padrão Ictus.</p>
        </header>

        <div className='flex items-center justify-center'>
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
            }}
            modules={[EffectFade, Pagination, Autoplay]}
            autoplay={{ delay: 5000 }}
            className='mySwiper'
          >
            <SwiperSlide className='flex w-full cursor-pointer flex-col gap-4 rounded-xl bg-[#006f45] p-8 text-white hover:opacity-75 md:w-1/2'>
              <a
                href='https://www.google.com/search?q=ictus+energia+solar&sca_esv=9d5b952a18faf0f8&ei=eG-SZsD0N5e_5OUPrPCk-A0&ved=0ahUKEwjAkLvr_qOHAxWXH7kGHSw4Cd8Q4dUDCA8&uact=5&oq=ictus+energia+solar&gs_lp=Egxnd3Mtd2l6LXNlcnAiE2ljdHVzIGVuZXJnaWEgc29sYXIyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIEMggQABiABBiiBEiECVBJWKgIcAF4AJABAJgBjQGgAYkGqgEDMC42uAEDyAEA-AEBmAIHoAKaBsICCxAAGIAEGLADGKIEwgILEAAYsAMYogQYiQXCAgYQABgWGB7CAggQABiiBBiJBZgDAIgGAZAGBZIHAzEuNqAH-xQ&sclient=gws-wiz-serp#lrd=0x94f36e65fdf279bb:0x6ba8b753a0e0d0ee,1,,,,&mpd=~13527062793570136092/customers/reviews'
                target='_blank'
              >
                <p className='mb-12 line-clamp-3 text-justify text-xl md:text-2xl'>
                  Atendimento diferenciado, rápido e com qualidade! Além de estar sempre monitorando o sistema após a
                  instalação o que garante o melhor desempenho!! Recomendo 👏🏼👏🏼
                </p>

                <div className='flex items-center gap-4'>
                  <img src={joao} alt='' className='w-16' />
                  <div className='flex flex-col gap-1'>
                    <span className='text-xl font-medium'>João Gustavo</span>
                    <img src={star} alt='5 estrelas' className='w-24' />
                  </div>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide className='flex w-full cursor-pointer flex-col gap-4 rounded-xl bg-[#006f45] p-8 text-white hover:opacity-75 md:w-1/2'>
              <a
                href='https://www.google.com/search?q=ictus+energia+solar&sca_esv=9d5b952a18faf0f8&ei=eG-SZsD0N5e_5OUPrPCk-A0&ved=0ahUKEwjAkLvr_qOHAxWXH7kGHSw4Cd8Q4dUDCA8&uact=5&oq=ictus+energia+solar&gs_lp=Egxnd3Mtd2l6LXNlcnAiE2ljdHVzIGVuZXJnaWEgc29sYXIyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIEMggQABiABBiiBEiECVBJWKgIcAF4AJABAJgBjQGgAYkGqgEDMC42uAEDyAEA-AEBmAIHoAKaBsICCxAAGIAEGLADGKIEwgILEAAYsAMYogQYiQXCAgYQABgWGB7CAggQABiiBBiJBZgDAIgGAZAGBZIHAzEuNqAH-xQ&sclient=gws-wiz-serp#lrd=0x94f36e65fdf279bb:0x6ba8b753a0e0d0ee,1,,,,&mpd=~13527062793570136092/customers/reviews'
                target='_blank'
              >
                <p className='mb-12 line-clamp-3 text-justify text-xl md:text-2xl'>
                  Propriedade nos depoimentos para explicação em relação ao produto. Ótimo relacionamento profissional x
                  cliente, excelência na instalação E um pós venda que me deixou ainda mais seguro .só tenho a
                  agradecer. Estou extremamente satisfeito.
                </p>

                <div className='flex items-center gap-4'>
                  <img src={sandrinho} alt='' className='w-16' />
                  <div className='flex flex-col gap-1'>
                    <span className='text-xl font-medium'>Sandrinho Mariano</span>
                    <img src={star} alt='5 estrelas' className='w-24' />
                  </div>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide className='flex w-full cursor-pointer flex-col gap-4 rounded-xl bg-[#006f45] p-8 text-white hover:opacity-75 md:w-1/2'>
              <a
                href='https://www.google.com/search?q=ictus+energia+solar&sca_esv=9d5b952a18faf0f8&ei=eG-SZsD0N5e_5OUPrPCk-A0&ved=0ahUKEwjAkLvr_qOHAxWXH7kGHSw4Cd8Q4dUDCA8&uact=5&oq=ictus+energia+solar&gs_lp=Egxnd3Mtd2l6LXNlcnAiE2ljdHVzIGVuZXJnaWEgc29sYXIyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIEMggQABiABBiiBEiECVBJWKgIcAF4AJABAJgBjQGgAYkGqgEDMC42uAEDyAEA-AEBmAIHoAKaBsICCxAAGIAEGLADGKIEwgILEAAYsAMYogQYiQXCAgYQABgWGB7CAggQABiiBBiJBZgDAIgGAZAGBZIHAzEuNqAH-xQ&sclient=gws-wiz-serp#lrd=0x94f36e65fdf279bb:0x6ba8b753a0e0d0ee,1,,,,&mpd=~13527062793570136092/customers/reviews'
                target='_blank'
              >
                <p className='mb-12 line-clamp-3 text-justify text-xl md:text-2xl'>
                  Empresa muito preocupada com o cliente, atendimento e instalações eficientes, muito ágil desde o
                  processo de negociação até seu funcionamento. Super recomendo de extrema confiança.
                </p>

                <div className='flex items-center gap-4'>
                  <img src={profile} alt='' className='w-16' />
                  <div className='flex flex-col gap-1'>
                    <span className='text-xl font-medium'>Graciele Schecluski</span>
                    <img src={star} alt='5 estrelas' className='w-24' />
                  </div>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide className='flex w-full cursor-pointer flex-col gap-4 rounded-xl bg-[#006f45] p-8 text-white hover:opacity-75 md:w-1/2'>
              <a
                href='https://www.google.com/search?q=ictus+energia+solar&sca_esv=9d5b952a18faf0f8&ei=eG-SZsD0N5e_5OUPrPCk-A0&ved=0ahUKEwjAkLvr_qOHAxWXH7kGHSw4Cd8Q4dUDCA8&uact=5&oq=ictus+energia+solar&gs_lp=Egxnd3Mtd2l6LXNlcnAiE2ljdHVzIGVuZXJnaWEgc29sYXIyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIEMggQABiABBiiBEiECVBJWKgIcAF4AJABAJgBjQGgAYkGqgEDMC42uAEDyAEA-AEBmAIHoAKaBsICCxAAGIAEGLADGKIEwgILEAAYsAMYogQYiQXCAgYQABgWGB7CAggQABiiBBiJBZgDAIgGAZAGBZIHAzEuNqAH-xQ&sclient=gws-wiz-serp#lrd=0x94f36e65fdf279bb:0x6ba8b753a0e0d0ee,1,,,,&mpd=~13527062793570136092/customers/reviews'
                target='_blank'
              >
                <p className='mb-12 line-clamp-3 text-justify text-xl md:text-2xl'>
                  Excelente negócio. Não precisei me envolver em nada além de assinar a documentação. Tudo foi
                  providenciado e bem conduzido pelo Alysson. Mesmo à distância, tudo resolvido e com excelente
                  qualidade. Muito satisfeito.
                </p>

                <div className='flex items-center gap-4'>
                  <img src={profile} alt='' className='w-16' />
                  <div className='flex flex-col gap-1'>
                    <span className='text-xl font-medium'>Fernando Alex Brenner</span>
                    <img src={star} alt='5 estrelas' className='w-24' />
                  </div>
                </div>
              </a>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  )
}
