import React, { useEffect } from 'react'
import Header from 'components/Header'
import Details from '../../components/Details/Details'
import Footer from '../../components/Footer/Footer'
import Bottom from '../../components/Bottom/Bottom'

export default function Sobre() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main className='bg-gray-300'>
      <Header />
      <div className='py-20'>
        <Details />
      </div>
      <Footer />
      <Bottom />
    </main>
  )
}
