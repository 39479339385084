import React from 'react'
import icon1 from 'assets/icons/1.svg'
import icon2 from 'assets/icons/2.svg'
import icon3 from 'assets/icons/3.svg'
import icon4 from 'assets/icons/4.svg'

function Destaques() {
  return (
    <section className='flex w-full items-center justify-center bg-[#006f45]'>
      <ul className='md:9/12 flex w-full items-center justify-between gap-4 p-6 md:justify-evenly md:gap-12'>
        <li className='flex flex-col items-center justify-center gap-2 text-center'>
          <img src={icon1} alt='' loading='lazy' className='h-8 w-8 md:h-10 md:w-10' />
          <span className='w-full text-xs text-white md:w-2/3 md:text-base'>+ de 8500 módulos em geração</span>
        </li>
        <li className='flex flex-col items-center justify-center gap-2 text-center'>
          <img src={icon2} alt='' loading='lazy' className='h-8 w-8 md:h-10 md:w-10' />
          <span className='w-full text-xs text-white md:w-1/2 md:text-base'>
            Já atendemos em +50 cidades no DF, MT, MS, PR, SC e RS
          </span>
        </li>
        <li className='flex flex-col items-center justify-center gap-2 text-center'>
          <img src={icon3} alt='' loading='lazy' className='h-8 w-8 md:h-10 md:w-10' />
          <span className='w-full text-xs text-white md:w-2/3 md:text-base'>
            Equipe de instalação e engenharia própria
          </span>
        </li>
        <li className='hidden flex-col items-center justify-center gap-2 text-center md:flex'>
          <img src={icon4} alt='' loading='lazy' className='h-8 w-8 md:h-10 md:w-10' />
          <span className='w-full text-xs text-white md:w-2/3 md:text-base'>+ de 17 GWh de produção</span>
        </li>
      </ul>
    </section>
  )
}

export default Destaques
