import React, { useState, useEffect } from 'react';

// global components
import Popup from 'components/Popup';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Bottom from 'components/Bottom';
import Depoimentos from 'components/Depoimentos';

// local components
import Home from './components/Home';
import ProjetandoSol from './components/ProjetandoSol';
import Clientes from './components/Clientes';
import Sobre from './components/Sobre';
import Simular from './components/Simular';
import Destaques from './components/Destaques';
import Contato from './components/Contato';
import SaibaMais from './components/SaibaMais';

function Inicio() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    document.title = 'ICTUS - SOLUÇÕES EM ENERGIA';
    window.scrollTo(0, 0);

    const timer = setTimeout(() => {
      setIsPopupVisible(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <>
      <Header />
      <Home />
      {/* <ProjetandoSol /> */}
      <Clientes />
      <Sobre />
      <Simular />
      <Destaques />
      <Depoimentos />
      <Contato />
      <SaibaMais />
      <Footer />
      <Bottom />
      {isPopupVisible && <Popup onClose={closePopup} />}
    </>
  );
}

export default Inicio;