export default function Simulator() {
  return (
    <article id='Simulator' className='flex w-full items-center justify-center bg-gray-300'>
      <section className='mb-20 mt-40 flex w-11/12 flex-col gap-4 md:w-10/12 md:items-center md:justify-center md:text-center'>
        <header className='flex flex-col gap-2 md:items-center md:justify-center'>
          <h3 className='text-2xl font-medium md:text-3xl'>FAÇA UMA SIMULAÇÃO</h3>
          <p className='w-full text-xl md:w-2/3'>
            Quer economizar com energia solar? Preencha os seus dados e um especialista entrará em contato para fazer
            uma simulação personalizada do seu sistema.{' '}
          </p>
        </header>

        <iframe
          src='https://forms.ploomes.com/form/26ac93cbd7ba44698791efd6fdfb0986?iframe=true'
          className='border-gray h-[500px] w-full rounded-xl border-2 bg-slate-100 md:h-[500px] md:w-[800px]'
        ></iframe>

        <article className='flex flex-col gap-2'>
          <p>
            Os seus dados serão tratados de acordo com nossa <span>politica de privacidade</span>
          </p>
          <div className='flex gap-2 md:text-center'>
            <input type='checkbox' id='politicaPrivacidade' required />
            <label>
              Li e aceito os <span>termos de uso</span> e <span>condições comercias</span>
            </label>
          </div>
        </article>
      </section>
    </article>
  )
}
