import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

function Navigation({ isOpen, onClose }) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  const handleClose = () => {
    onClose()
  }

  const handleLinkClick = () => {
    handleClose()
  }

  const overlayClass = isOpen ? 'opacity-75' : 'opacity-0 pointer-events-none'
  const translateClass = isOpen ? 'translate-x-0' : 'translate-x-full'

  return (
    <section id='nav' className='transition-all'>
      <div
        className={`fixed inset-0 z-40 bg-black ${overlayClass} cursor-pointer backdrop-blur-2xl backdrop-filter duration-500 ease-in-out`}
        onClick={handleClose}
      />
      <nav
        className={`fixed inset-y-0 right-0 z-50 flex w-10/12 flex-col justify-center gap-12 bg-white bg-opacity-75 p-8 shadow-lg backdrop-blur-2xl duration-1000 ease-in-out md:w-1/3 ${translateClass}`}
      >
        <ul className='flex flex-col gap-4'>
          <li>
            <a
              href='https://ictus.solar/'
              onClick={handleLinkClick}
              className='text-3xl font-semibold uppercase text-black hover:text-[#50af8a] hover:underline'
            >
              Página Inicial
            </a>
          </li>
          <li>
            <Link
              to='https://ictus.solar/simulacao'
              onClick={handleLinkClick}
              className='text-3xl font-semibold uppercase text-black hover:text-[#50af8a] hover:underline'
            >
              Faça uma simulação
            </Link>
          </li>
          <li>
            <Link
              to='https://ictus.solar/depoimentos'
              onClick={handleLinkClick}
              className='text-3xl font-semibold uppercase text-black hover:text-[#50af8a] hover:underline'
            >
              Depoimentos
            </Link>
          </li>

          <li>
            <Link
              to='https://ictus.solar/contatos'
              onClick={handleLinkClick}
              className='text-3xl font-semibold uppercase text-black hover:text-[#50af8a] hover:underline'
            >
              Contato
            </Link>
          </li>
          <li>
            <Link
              to='https://ictus.solar/sobre'
              onClick={handleLinkClick}
              className='text-3xl font-semibold uppercase text-black hover:text-[#50af8a] hover:underline'
            >
              Sobre
            </Link>
          </li>
        </ul>
      </nav>
    </section>
  )
}

export default Navigation
