import React from 'react'
import about from 'assets/images/about.png'
import { Link } from 'react-router-dom'

function Sobre() {
  return (
    <section className='flex w-full items-center justify-center'>
      <div className='my-10 grid w-11/12 grid-cols-1 md:grid-cols-2 items-center gap-8 md:my-20 md:w-10/12'>
        <div className='relative w-full' style={{ paddingBottom: '56.25%' }}>
          <iframe
             className='absolute top-0 left-0 w-full h-full rounded-lg border-0'
             src="https://www.youtube.com/embed/1JJLVXtX8WA?autoplay=1"
             title="Vídeo Institucional: Nós somos a ICTUS"
             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
             referrerPolicy="strict-origin-when-cross-origin"
             allowFullScreen>
          </iframe>
        </div>

        <article className='flex flex-col gap-2'>
          <header className='text-3xl font-semibold uppercase md:text-4xl'>Quem Somos</header>

          <p className='text-justify text-xl'>
            Somos uma empresa especializada, com atuação em diversas regiões do Brasil, em projetos variados, incluindo residências, comércios, propriedades rurais, indústrias e órgãos públicos. Nossa confiabilidade resulta em uma média de 445.000 kWh por mês, atendendo mais de 50 cidades.
          </p>

          <p className='text-justify text-xl'>
            Na ICTUS SOLUÇÕES EM ENERGIA, valorizamos cada cliente individualmente, comprometendo-nos a estabelecer conexões transparentes e honestas.
          </p>

          <Link
            to='/sobre'
            className='w-full rounded-xl bg-[#006f45] p-4 text-center text-2xl uppercase text-white hover:opacity-75 md:w-2/3 md:text-2xl'
          >
            Conheça nossa história
          </Link>
        </article>
      </div>
    </section >
  )
}

export default Sobre
