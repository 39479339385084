import React from 'react'
import background from 'assets/images/contact.webp'
import whatsapp from 'assets/icons/whatsapp.svg'

function Contato() {
  return (
    <section
      className='flex min-h-96 w-full items-center justify-center bg-cover bg-center bg-no-repeat'
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className='my-10 flex w-11/12 flex-col items-center justify-center gap-4 md:text-center uppercase text-white md:my-20 md:w-9/12'>
        <div className='flex flex-1 items-center justify-center text-center gap-2'>
          <p className='text-3xl font-semibold uppercase md:text-5xl'>Serviço personalizado em sua simulação</p>
        </div>
        <div className='flex flex-1 flex-col items-center justify-center gap-8'>
          <p className='w-full md:text-2xl'>
            Garanta um serviço humanizado, personalizado e dedicado a você! <br /> Dúvidas sobre energia solar?
          </p>
          <a
            href='https://api.whatsapp.com/send/?phone=554499440094&text&type=phone_number&app_absent=0'
            target='_blank'
            className='rounded-xl bg-white p-4 text-center text-xl md:text-2xl font-medium text-black hover:opacity-75 md:w-1/2 md:text-3xl'
          >
            Fale com nosso time
          </a>
        </div>
      </div>
    </section>
  )
}

export default Contato
