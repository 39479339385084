import React, { Suspense, useState, useEffect } from 'react'
import Header from 'components/Header'
import Simulator from '../../components/Simulator/Simulator'
import Footer from '../../components/Footer/Footer'
import Bottom from '../../components/Bottom/Bottom'

export default function SimulatorPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main>
      <Header />
      <Simulator />
      <Footer />
      <Bottom />
    </main>
  )
}
