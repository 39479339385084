import React from 'react'
import background from 'assets/images/simulator.webp'

function Simular() {
  return (
    <section
      className='flex items-center justify-center bg-cover bg-center bg-no-repeat'
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className='my-10 flex w-11/12 flex-col justify-center gap-8 text-white md:my-20 md:w-10/12'>
        <header className='flex flex-col gap-4'>
          <h2 className='text-3xl font-semibold uppercase md:text-5xl'>FAÇA UMA SIMULAÇÃO</h2>

          <p className='w-full text-justify md:w-8/12 md:text-2xl'>
            Quer economizar com energia solar? Preencha os seus dados e um especialista entrará em contato para fazer
            uma simulação personalizada do seu sistema.
          </p>
        </header>

        <div>
          <iframe
            src='https://forms.ploomes.com/form/26ac93cbd7ba44698791efd6fdfb0986?iframe=true'
            className='border-gray h-[500px] w-full overflow-hidden rounded-xl border-2 bg-slate-100 md:h-[500px] md:w-[800px]'
          ></iframe>
        </div>
      </div>
    </section>
  )
}

export default Simular
