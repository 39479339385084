import React from 'react'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import Inicio from './pages/Inicio/Inicio'
import Sobre from './pages/Sobre/Sobre'
import Simulacao from './pages/SimulatorPage/SimulatorPage'
import Depoimentos from './pages/ReviewPage/ReviewPage'
import Contato from './pages/Contato/Contato'

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Inicio />} />
        <Route path='/simulacao' element={<Simulacao />} />
        <Route path='/depoimentos' element={<Depoimentos />} />
        <Route path='/contatos' element={<Contato />} />
        <Route path='/sobre' element={<Sobre />} />
      </Routes>
    </Router>
  )
}
