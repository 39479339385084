  import React from 'react'

  import logoUTFPR from 'assets/empresas/UTFPR - CAMPUS APUCARANA.png'
  import logoSEBRAE from 'assets/empresas/SEBRAE MS.png'
  import logoTRT9 from 'assets/empresas/TRT9 - JUSTIÇA DO TRABALHO PR.png'
  import logoTRT23 from 'assets/empresas/TRT23 - JUSTIÇA DO TRABALHO MT.jpeg'
  import logoIFPR from 'assets/empresas/IFPR CAMPUS FOZ DO IGUAÇU.png'
  import logoBarra from 'assets/empresas/MUNICÍPIO DE BARRA DO RIBEIRO - RS.jpg'
  import logoBrasilandia from 'assets/empresas/MUNICÍPIO DE BRASILÂNDIA DO SUL.jpeg'
  import logoGuaira from 'assets/empresas/MUNICÍPIO DE GUAÍRA.jpeg'
  import logoIbema from 'assets/empresas/MUNICÍPIO DE IBEMA.png'
  import logoMarialva from 'assets/empresas/MUNICÍPIO DE MARIALVA.png'
  import logoMercedes from 'assets/empresas/MUNICÍPIO DE MERCEDES.png'
  import logoRamilandia from 'assets/empresas/MUNICÍPIO DE RAMILÂNDIA.png'
  import logoRioBom from 'assets/empresas/MUNICÍPIO DE RIO BOM.jpeg'
  import logoSaoManoel from 'assets/empresas/MUNICÍPIO DE SÃO MANOEL DO PARANÁ.png'
  import logoTerraRoxa from 'assets/empresas/MUNICÍPIO DE TERRA ROXA.png'
  import logoToledo from 'assets/empresas/MUNICÍPIO DE TOLEDO.png'
  import logoSenado from 'assets/empresas/SENADO FEDERAL.jpg'
  import logoSantaInes from 'assets/empresas/SUPERMERCADO SANTA INÊS - IPORÃ PR.png'
  import logoACFarma from 'assets/empresas/AC FARMA - PALOTINA.jpg'
  import logoRacaPet from 'assets/empresas/CLÍNICA RAÇA PET SHOP.png'
  import logoDallmoveis from 'assets/empresas/DALLMÓVEIS PLANEJADOS PALOTINA.jpeg'
  import logoGebon from 'assets/empresas/GEBON - PALOTINA.png'
  import logoHMTornearia from 'assets/empresas/HM TORNEARIA E METALURGICA - PALOTINA.jpg'
  import logoLiquigas from 'assets/empresas/LIQUIGÁS - PALOTINA.jpeg'
  import logoMecanicaCentral from 'assets/empresas/MECÂNICA CENTRAL - IVAIPORÃ.png'
  import logoMultquimica from 'assets/empresas/MULTQUÍMICA - LONDRINA.jpeg'

  function Clientes() {
    // Array de objetos com nome, logo, cidade, módulos e ano correspondentes
    const clientes = [
      { nome: 'UTFPR - Campus Apucarana', logo: logoUTFPR, cidade: 'Guarapuava', modulos: 404, ano: 2023, style: 'p-2', image: 'scale-100'},
      { nome: 'IFPR Campus Foz do Iguaçu', logo: logoIFPR, cidade: 'Foz do Iguaçu - PR', modulos: 143, ano: 2021, style: 'p-2', image: 'scale-100'},
      {
        nome: 'Município de Barra do Ribeiro - RS',
        logo: logoBarra,
        cidade: 'Barra do Ribeiro - RS',
        modulos: 120,
        ano: 2023,
        style: 'p-2', image: 'scale-125'},
      { nome: 'Município de Ibema', logo: logoIbema, cidade: 'Ibema - PR', modulos: 180, ano: 2024, style: 'p-2', image: 'scale-100'},
      { nome: 'Município de Marialva', logo: logoMarialva, cidade: 'Marialva - PR', modulos: 62, ano: 2023, style: 'p-2', image: 'scale-100'},
      { nome: 'Município de Mercedes', logo: logoMercedes, cidade: 'Mercedes - PR', modulos: 174, ano: 2022, style: 'p-2', image: 'scale-125'},
      { nome: 'Município de Ramilândia', logo: logoRamilandia, cidade: 'Ramilândia - PR', modulos: 152, ano: 2023, style: 'p-2', image: 'scale-100'},
      { nome: 'Município de Rio Bom', logo: logoRioBom, cidade: 'Rio Bom - PR', modulos: 190, ano: 2023, style: 'p-2', image: 'scale-100'},
      {
        nome: 'Município de São Manoel do Paraná',
        logo: logoSaoManoel,
        cidade: 'São Manoel do PR',
        modulos: 48,
        ano: 2023,
     style: 'p-5', image: 'scale-100'},
      { nome: 'Município de Toledo', logo: logoToledo, cidade: 'Toledo - PR', modulos: 174, ano: 2022, style: 'p-2', image: 'scale-100'},
      { nome: 'Sebrae MS', logo: logoSEBRAE, cidade: 'Corumbá- MS', modulos: 51, ano: 2024, style: 'p-2', image: 'scale-100'},
      { nome: 'Senado Federal', logo: logoSenado, cidade: 'Brasília -DF', modulos: 326, ano: 2023, style: 'p-2', image: 'scale-100'},
      { nome: 'Município de Terra Roxa', logo: logoTerraRoxa, cidade: 'Terra Roxa - PR', modulos: 456, ano: 2022, style: 'p-2', image: 'scale-100'},
      
      // Clientes sem padding
      { nome: 'TRT9 - Justiça do Trabalho PR', logo: logoTRT9, cidade: '16 cidades - PR', modulos: 2390, ano: 2021, style: 'p-0', image: 'scale-100'},
      { nome: 'TRT23 - Justiça do Trabalho MT', logo: logoTRT23, cidade: 'Cuiabá - MT', modulos: 392, ano: 2024, style: 'p-0', image: 'scale-100'},
      {
        nome: 'Município de Brasilândia do Sul',
        logo: logoBrasilandia,
        cidade: 'Brasilândia do Sul - PR',
        modulos: 158,
        ano: 2023,
     style: 'p-2 bg-[#0A3770]', image: 'scale-100'},
      { nome: 'Liquigás - Palotina', logo: logoLiquigas, cidade: 'Palotina - PR', modulos: 12, ano: 2021, style: 'p-0', image: 'scale-100'},
      { nome: 'AC Farma - Palotina', logo: logoACFarma, cidade: 'Palotina - PR', modulos: 78, ano: 2021, style: 'p-0', image: 'scale-100'},
      { nome: 'Mecânica Central - Ivaiporã', logo: logoMecanicaCentral, cidade: 'Ivaiporã - PR', modulos: 18, ano: 2022, style: 'p-0', image: 'scale-100'},
      { nome: 'Clínica Raça Pet Shop', logo: logoRacaPet, cidade: 'Palotina - PR', modulos: 61, ano: 2021, style: 'p-0', image: 'scale-100'},
      { nome: 'Supermercado Santa Inês - Iporã PR', logo: logoSantaInes, cidade: 'Iporã - PR', modulos: 254, ano: 2022, style: 'p-0 bg-[#F68634]', image: 'scale-100'},
      { nome: 'Gebon - Palotina', logo: logoGebon, cidade: 'Palotina - PR', modulos: 36, ano: 2024, style: 'p-0', image: 'scale-100'},
      {
        nome: 'HM Torneiraria e Metalúrgica - Palotina',
        logo: logoHMTornearia,
        cidade: 'Palotina - PR',
        modulos: 30,
        ano: 2021,
     style: 'p-0', image: 'scale-100'},
      { nome: 'Dallmóveis Planejados Palotina', logo: logoDallmoveis, cidade: 'Palotina - PR', modulos: 24, ano: 2024, style: 'p-0', image: 'scale-150'},
      { nome: 'Multquímica - Londrina', logo: logoMultquimica, cidade: 'Londrina - PR', modulos: 90, ano: 2024, style: 'p-0 bg-[#FAFEFF]', image: 'scale-[150%]'},
    ]

    return (
      <section className='flex w-full items-center justify-center'>
        <div className='my-10 flex w-11/12 flex-col items-center justify-center gap-4 md:gap-8 md:my-20 md:w-10/12'>
          <header className='text-3xl font-semibold uppercase md:text-5xl'>Clientes que confiam na gente</header>

          <ul className='grid grid-cols-4 items-center justify-center gap-4 md:grid-cols-4 lg:grid-cols-9 sca'>
            {clientes.map((cliente, index) => (
              <li
                key={index}
                className={`relative flex h-20 w-20 flex-col items-center justify-center overflow-hidden rounded-full border-4 border-[#50af8a] border-opacity-85 shadow-lg md:h-28 md:w-28 lg:h-32 lg:w-32 ${cliente.style} `}
              >
                <img src={cliente.logo} alt={cliente.nome} loading='lazy' className={`max-h-full max-w-full object-cover ${cliente.image}`} />
                <div className='absolute inset-0 flex cursor-pointer flex-col items-center justify-center bg-[#474642] bg-opacity-85 p-1 text-center text-white opacity-0 transition-opacity duration-500 hover:opacity-100'>
                  <p className='text-[10px] uppercase md:text-[12px] lg:text-[14px]'>Módulos: {cliente.modulos}</p>
                  <p className='text-[10px] uppercase md:text-[12px] lg:text-[14px]'>Ano: {cliente.ano}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
    )
  }

  export default Clientes
