import React, { useEffect } from 'react'
import Header from 'components/Header'
import Bottom from 'components/Bottom'
import Depoimentos from 'components/Depoimentos'
import Footer from 'components/Footer'

export default function ReviewPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main className='bg-gray-300'>
      <Header />
      <div className='py-20'>
        <Depoimentos />
      </div>
      <Footer />
      <Bottom />
    </main>
  )
}
